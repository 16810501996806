export const USERNAME_MIN_LENGTH = 1;
export const USERNAME_MAX_LENGTH = 25;
export const EMAIL_MAX_LENGTH = 70;
export const PASSWORD_MIN_LENGTH = 8;

export const PROFILE_DESCRIPTION_MAX_LENGTH = 200;
export const AVATAR_FILE_MAX_SIZE = 2_000_000; // 2 MB
export const ALLOWED_IMAGE_FILE_TYPES = ["image/png", "image/jpeg", "image/webp", "image/bmp"];
export const POST_IMAGE_FILE_MAX_SIZE = 3_000_000; // 3 MB
export const IMAGES_PER_POST_LIMIT = 4;

export const POST_MAX_LENGTH = 2000;
export const POST_COMMENT_MAX_LENGTH = 500;

export const VIDEO_GAME_REVIEW_MAX_LENGTH = 1000;
export const STORY_MAX_LENGTH = 1000;

export const SESSION_COOKIE_NAME = "session-id";
export const SESSION_STATE_COOKIE_NAME = "session-id-state";
export const XSRF_TOKEN_COOKIE_NAME = "XSRF-TOKEN";

export const META_TITLE_MAX_LENGTH = 65;
export const META_DESC_MAX_LENGTH = 160;

export const SEARCH_QUERY_MAX_LENGTH = 25;
export const SEARCH_DELAY = 450;
