import { Action, ThunkAction, combineReducers, configureStore } from "@reduxjs/toolkit";

import authReducer from "../../auth/store/authSlice";

const reducer = combineReducers({
    auth: authReducer,
});

export const store = configureStore({
    reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
