import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { GetServerSidePropsContext } from "next";
import { ParsedUrlQuery } from "node:querystring";

import { SESSION_COOKIE_NAME } from "../../main/utils/globalConst";

export function getStatusCodeFromError(error: any): number {
    const axiosError = error as AxiosError;
    if (!axiosError || !axiosError.response || !axiosError.response.status) {
        return null;
    }
    return axiosError.response.status;
}

export function appendToFormData(formData: FormData, obj: any) {
    for (const key in obj) {
        if (obj[key] !== undefined && obj[key] !== null && obj[key] !== "") {
            const value = typeof obj[key] === "object" ? JSON.stringify(obj[key]) : obj[key];
            formData.append(key, value);
        }
    }
}

export function isRequestCanceledError(error: any): boolean {
    const axiosError = error as AxiosError;
    return Boolean(axiosError && axiosError.constructor && axiosError.constructor.name === "Cancel");
}

export function getServerSideAxios(context: GetServerSidePropsContext<ParsedUrlQuery>) {
    const sessionId = context.req.cookies[SESSION_COOKIE_NAME];
    const config: AxiosRequestConfig = {
        baseURL: process.env.API_URL_SERVER_SIDE,
    };
    if (sessionId) {
        config.headers = { Cookie: `${SESSION_COOKIE_NAME}=${sessionId}` };
    }
    return axios.create(config);
}
