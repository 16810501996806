import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../main/store/store";

interface State {
    externalRegistrationToken: string;
    externalProviderName: string;
}

const initialState: State = {
    externalRegistrationToken: null,
    externalProviderName: null,
};

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        externalTokenReceived: (state, action: PayloadAction<State>) => {
            Object.assign(state, action.payload);
        },

        externalTokenUsed: (state) => {
            state.externalRegistrationToken = null;
            state.externalProviderName = null;
        },
    },
});

export const { externalTokenReceived, externalTokenUsed } = slice.actions;

export const getExternalRegistrationState = (state: RootState) => state.auth;

export default slice.reducer;
