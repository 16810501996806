import { ParsedUrlQuery } from "querystring";

import axios, { AxiosInstance, CancelToken } from "axios";
import { GetServerSidePropsContext } from "next";

import { getServerSideAxios } from "../../infrastructure/utils/axiosHelper";
import { PostDto } from "../../posts/models/dto/postDto";
import { MeDto } from "../models/dto/meDto";
import { UserProfileDto } from "../models/dto/userProfileDto";

export class UserService {
    private _axios: AxiosInstance;

    constructor(axiosInstance?: AxiosInstance) {
        this._axios = axiosInstance || axios;
    }

    public static serverSide(context: GetServerSidePropsContext<ParsedUrlQuery>) {
        return new UserService(getServerSideAxios(context));
    }

    public async me() {
        return await this._axios.get<MeDto>("users/me", { withCredentials: true });
    }

    public async profile(username: string) {
        return await this._axios.get<UserProfileDto>(`users/@${username}/profile`, { withCredentials: true });
    }

    public async updateProfile(description: string, newsletter: boolean) {
        return await this._axios.patch<MeDto>("users/me", { description, newsletter }, { withCredentials: true });
    }

    public async updateAvatar(epochStartingYear: number, avatarFile?: File) {
        const formData = new FormData();
        if (avatarFile) {
            formData.append("avatar", avatarFile);
        }
        return await this._axios.post<Record<number, string>>(`users/${epochStartingYear}/me/avatar`, formData, { withCredentials: true });
    }

    public async updateSocialMedia(youTubeChannel: string, facebookProfile: string, twitterProfile: string, instagramProfile: string) {
        return await this._axios.patch<UserProfileDto>(
            "users/me/social-media",
            {
                youTubeChannel,
                facebookProfile,
                twitterProfile,
                instagramProfile,
            },
            { withCredentials: true }
        );
    }

    public async changePassword(oldPassword: string, password: string) {
        return await this._axios.post(
            "user/password/change",
            { old: oldPassword, new: password },
            { baseURL: process.env.NEXT_PUBLIC_AUTH_API_URL, withCredentials: true }
        );
    }

    public async changeFollowerRelation(username: string) {
        return await this._axios.post<{ result: boolean }>(`users/@${username}/follower-relation`, null, { withCredentials: true });
    }

    public async getFeedPosts(cancelToken: CancelToken, epochStartingYear: number, lastPostId?: string) {
        return await this._axios.get<{ posts: PostDto[]; hasMore: boolean }>(`users/${epochStartingYear}/me/feed`, {
            params: { last: lastPostId },
            cancelToken,
            withCredentials: true,
        });
    }
}

export const userService = new UserService();
