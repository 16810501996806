export const pageview = (url: string) => {
    (window as any).gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
    });
};

interface GaEvent {
    action: string;
    params: Record<string, string>;
}

export const event = ({ action, params }: GaEvent) => {
    (window as any).gtag("event", action, params);
};
