import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";
import { Dispatch, SetStateAction, createContext, useContext } from "react";

import { isDevEnvironment } from "../main/utils/appUtils";
import { SESSION_STATE_COOKIE_NAME, XSRF_TOKEN_COOKIE_NAME } from "../main/utils/globalConst";
import { AuthenticatedUser } from "../users/models/authenticatedUser";
import { userCache } from "../users/services/userCache";

type AuthContextValue = { user: AuthenticatedUser; setUser: Dispatch<SetStateAction<AuthenticatedUser>> };

export const AuthContext = createContext<AuthContextValue>({ user: null, setUser: null });

export function useUser() {
    const { user, setUser } = useContext(AuthContext);

    function signInInContext(authenticatedUser: AuthenticatedUser) {
        signIn(authenticatedUser, setUser);
    }

    function signOutInContext() {
        signOut(setUser);
    }

    return { user, signIn: signInInContext, signOut: signOutInContext };
}

export function signIn(authenticatedUser: AuthenticatedUser, setUser: Dispatch<SetStateAction<AuthenticatedUser>>) {
    setUser(authenticatedUser);
    userCache.save(authenticatedUser);
    Sentry.setUser({ id: authenticatedUser.id });
}

export function signOut(setUser: Dispatch<SetStateAction<AuthenticatedUser>>) {
    const cookieOptions: Cookies.CookieAttributes = { domain: isDevEnvironment ? undefined : "nostwave.com" };
    Cookies.remove(SESSION_STATE_COOKIE_NAME, cookieOptions);
    Cookies.remove(XSRF_TOKEN_COOKIE_NAME, cookieOptions);
    localStorage.clear();
    setUser(null);
    Sentry.setUser(null);
}
