import { AuthenticatedUser } from "../models/authenticatedUser";

const key = "userData";
export class UserCache {
    public save(user: AuthenticatedUser) {
        const json = JSON.stringify(user);
        const base64 = Buffer.from(json).toString("base64");
        localStorage.setItem(key, base64);
    }

    public get(): AuthenticatedUser {
        const base64 = localStorage.getItem(key);
        if (!base64) {
            return null;
        }
        const json = Buffer.from(base64, "base64").toString();
        return JSON.parse(json) as AuthenticatedUser;
    }
}

export const userCache = new UserCache();
