import "@fortawesome/fontawesome-svg-core/styles.css"; // TODO TEMPORARY FIX: next/head with link element breaks Font Awesome Icons: https://github.com/vercel/next.js/issues/20682
import * as Sentry from "@sentry/nextjs";
import axios from "axios";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { SSRProvider } from "react-bootstrap";
import "react-day-picker/dist/style.css";
import "react-image-lightbox/style.css";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext, signIn, signOut } from "../auth/authContext";
import { hasSessionCookie } from "../auth/utils/hasSessionCookie";
import { getStatusCodeFromError } from "../infrastructure/utils/axiosHelper";
import { useNProgress } from "../main/hooks/useNProgress";
import { store } from "../main/store/store";
import * as ga from "../main/utils/googleAnalytics";
import { useIsomorphicLayoutEffect } from "../main/utils/useIsomorphicLayoutEffect";
import "../style/index.scss";
import { AuthenticatedUser } from "../users/models/authenticatedUser";
import { MeDto } from "../users/models/dto/meDto";
import { userCache } from "../users/services/userCache";
import { userService } from "../users/services/userService";

export const MyApp: NextPage<AppProps> = ({ Component, pageProps }) => {
    const [user, setUser] = useState<AuthenticatedUser>(null);
    const router = useRouter();
    useNProgress();

    useIsomorphicLayoutEffect(() => {
        if (!hasSessionCookie()) {
            return;
        }

        async function loadMe() {
            let meData: MeDto;
            try {
                const response = await userService.me();
                meData = response.data;
            } catch (error) {
                if (getStatusCodeFromError(error) === 401) {
                    router.replace("/");
                    signOut(setUser);
                    return;
                }
                Sentry.captureException(error);
                return;
            }

            signIn(meData, setUser);
        }

        const fromCache = userCache.get();
        if (fromCache) {
            signIn(fromCache, setUser);
        }

        loadMe();
    }, []);

    useEffect(() => {
        if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
            return;
        }

        const handleRouteChange = (url: string) => ga.pageview(url);
        router.events.on("routeChangeComplete", handleRouteChange);

        return () => router.events.off("routeChangeComplete", handleRouteChange);
    }, [router.events]);

    return (
        <SSRProvider>
            <Provider store={store}>
                <AuthContext.Provider value={{ user, setUser }}>
                    <Component {...pageProps} />

                    {/* ToastContainer must be in _app so it can persist after a page navigation */}
                    <ToastContainer />
                </AuthContext.Provider>
            </Provider>
        </SSRProvider>
    );
};

export default MyApp;

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;
